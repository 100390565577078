import auth from "./auth"

class API {
    constructor(){
        this.user = auth.user
        this.phone = auth.phone
        this.role = auth.role
        this.write=false
    }
    setFunc(func){
        this.func = func
        return this
    }
    setAssessmentId(assessmentId){
        this.assessmentId=assessmentId
        return this
    }
    setParams(...params){
        this.params=params
        return this
    }
    setPhone(phone){
        this.phone=phone
        return this
    }
    setBody(body){
        this.body=body
        return this
    }
    setWrite(){
        this.write=true
        return this
    }
    detachUser(){
        this.phone=""
        return this
    }
    async call(){

        const res = await fetch('/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                user: this.user,
                phone: this.phone,
                role: this.role,
            },
            body: JSON.stringify({
                func:this.func,
                params: this.params,
                assessmentId: this.assessmentId,
                body: this.body,
                write: this.write,
            }),
        })
        return res.json().then((res)=>{
            if(res.error){
                throw new Error(res.msg)
            }
            if(!res.data){
                throw new Error(`Unexpected response in data: ${res.data}`)
            }
            return res.data
        })
    }
}


export default API