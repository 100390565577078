import "./question.css"
import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography,
    Button,
    Paper,
    Divider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    option: {
        display: "initial",
        textTransform: "none",
        margin: "0.9vh",
        width: "50vw",
        textAlign: "inherit",
        border: "1px solid rgba(0,0,0,0.2)",
        fontFamily: "'Poppins', sans-serif",
        fontSize: "1.7vh",
        fontWeight: theme.typography.fontWeightMedium,
    },
    paper:{
        width: "95vw",
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)",
        padding: "2%",
    },
    question:{
        fontFamily: "'Poppins', sans-serif",
        fontSize: "1.9vh",
        fontWeight: theme.typography.fontWeightBold,
        margin: "0 0 1vh 0"
    }
  }));


export default function Question(props){
    var self = this
    const {question, options, answer, selected} = props.details
    const {showCorrect} = props
    var [clicked, setClicked] = useState()
    const classes = useStyles();
    const alpha = ["a","b","c","d"]

    const optionClick = (i)=>{
        setClicked(i+"")
        const path = window.location.href.replace(window.location.origin, "")
        var data = JSON.parse(localStorage.getItem(path)) || {}
        data[props.n]=i
        localStorage.setItem(path,JSON.stringify(data))
    }

    return <Paper variant="outlined" elevation={5} className={classes.paper}>
        <Typography className={classes.question}>{`${parseInt(props.n)+1}. ${question}`}</Typography>
        <Divider/>
        <div className={"options"}>
            {(options).map((o, i)=>{
                var optionClass = ""
                if(showCorrect){
                    if(i==answer){
                        optionClass = " correctOption"
                    }
                    if(i==selected && i!=answer){
                        optionClass = " wrongOption"
                    }
                }else{
                    if(clicked==i+""){
                        optionClass = " selectedOption"
                    }
                }
                return <Button
                        disabled={showCorrect}
                        onClick={!showCorrect && optionClick.bind(self, i)}
                        className={classes.option + " option" + optionClass}
                    >{`${alpha[i]}. ${o}`}</Button>
            })}
        </div>
    </Paper>
}