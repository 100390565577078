import React, { useEffect, useRef, useState } from "react";
import { Form, Button, ProgressBar } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./login.css"
import cookie from "../../scripts/cookie";
import auth from "../../scripts/auth";
import API from "../../scripts/api";
import { useParams, useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function Login(){

    let query = useQuery();
    
    const phone = useRef(null)

    const [wait, setWait] = useState(false)
    const [validPhone, setValidPhone] = useState(false)
    const redirectPath = query.get("redirect") || "home"

    useEffect(()=>{
        document.title=`Gita Online Course - Login`
        auth.redirect(redirectPath)
        phone.current.focus()
    },[])


    const onSubmit=(e)=>{
        e.preventDefault()

        toast.dismiss()
        setWait(true)

        new API()
        .setFunc("getRole")
        .setParams(phone.current.value)
        .call()
        .then(res => {
            switch(res.role){
                case "stranger":
                    toast.warn(<div style={{color:"black"}}>You must have entered incorrect or unregistered phone number!</div>)
                    break
                case "admin":
                case "user":
                    cookie.set(res)
                    window.open(redirectPath,"_self")
                    break
                default:
                    toast.error(`Unexpected response in role: ${res}`)
            }
            setWait(false)
        })
        .catch((error) => {
            toast.error(error)
            setWait(false)
        });
    }

    return <div>
        <ProgressBar id="wait" animated now={100} style={{visibility:wait?"visible":"hidden"}}/>
      
        <ToastContainer
            position="top-right"
            autoClose={false}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <div className="title">Gita Online Course</div>
        <img className="book" src="gita_login.png" alt="Srimad Bhagavad-Gita As It Is"></img>

        <Form className="form" onSubmit={wait?null:onSubmit}>
            <Form.Control 
                ref={phone}
                type="text"
                placeholder="Enter your registered phone number"
                id="phone"
                onChange={(e)=>{
                    setValidPhone(!!e.target.value)
                }}/>
            <Button variant="primary" type="submit" id="login" disabled={wait||!validPhone}>{wait?"Loggining you in...":"Login"}</Button>
        </Form>
    </div>
}