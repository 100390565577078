import cookie from "./cookie"

const auth = {
    user: cookie.get("name"),
    phone: cookie.get("phone"),
    role: cookie.get("role")
}
const redirect = (url)=>{

    const cookiesPresent =  auth.user && auth.phone && auth.role

    if (url){
        if(cookiesPresent){
            window.open(url,"_self")
        }else{
            return
        }
    }else{
        if(cookiesPresent){
            return
        }else{
            window.open(`/?redirect=${window.location.pathname}`,"_self")
        }
    }

}

auth.redirect = redirect

export default auth