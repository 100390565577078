import "./progress.css"

export default function Progress(props){
    return (
        <div className={"progressHolder"}>
            <div className={"progressText"}>{props.waitText || "waiting..."}</div>
            <div className={"progressSlide"} style={{ width: (props.progress||0) + "vw", backgroundColor: props.backgroundColor}}>
                
            </div>
        </div>
    )

}