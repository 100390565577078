import React, { useEffect, useState, useRef } from "react";
import API from "../../scripts/api";
import Header from "../../components/header/header";
import { useParams, useLocation } from "react-router-dom";
import "./assessment.css"
import Question from "../../components/question/question";
import Progress from "../../components/progress/progress";
import { 
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import auth from "../../scripts/auth";
import Loader from "../../components/loader/loader";
import "moment-timezone"
var moment = require('moment');
moment.tz.setDefault("Asia/Kolkata");

const zeroPad = (num, places) => String(num).padStart(places, '0')

const useStyles = makeStyles((theme) => ({
    heading: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "1.7vh",
        fontWeight: theme.typography.fontWeightBold,
    },
    instructions: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "1.7vh",
        fontWeight: theme.typography.fontWeightMedium,
    },
    startNow: {
        display: "block",
        textTransform: "none",
        margin: "0.9vh",
        fontSize: "1.5vh"
    },
    submit: {
        fontFamily: "'Poppins', sans-serif",
        display: "block",
        textTransform: "none",
        fontSize: "2vh",
        margin: "2vh 0 0 0",
        position: "relative",
        left: "50%",
        backgroundColor: "#ec407a",
        transform: "translateX(-50%)"
    },
    submitBox:{
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)",
        margin: "3vh 0 0 0"
    },
    details: {
        display: "block"
    },
  }));

  
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function getTime(time){
    return moment(time, "DD-MM-YYYY;HH:mm")
}

function showError(e){
    toast.error(e.toString())
}

export default function Assessment(){

    var [showInstructions, setShowInstructions] = useState(false)
    var [scheduledAssessment, setScheduledAssessment] = useState()
    var [timeRemaining, setTimeRemaining] = useState()
    var [wait, setWait] = useState(false)

    var top = useRef()

    const classes = useStyles();
    useEffect(auth.redirect,[])

    const {assessmentId} = useParams()
    let query = useQuery();

    var [assessmentDetails, setAssessmentDetails] = useState({})

    const getAssessmentDetails = ()=>{
        new API()
        .setFunc("getAssessmentDetails")
        .setParams(assessmentId, auth.phone)
        .setAssessmentId(assessmentId)
        .call()
        .then((res)=>{
            if(res.startTime && !res.endTime){
                const path = window.location.href.replace(window.location.origin, "")
                var data = JSON.parse(localStorage.getItem(path)) || {}
                for(var i in data){
                    res.file[parseInt(i)].selected = data[i]
                }
            }
            setAssessmentDetails(res)
            setShowInstructions(res.assessmentId)
            setScheduledAssessment(res.assessmentId?1:0)
        })
        .catch(showError)
    }
    
    var timer

    useEffect(()=>{
        document.title=`Gita Online Course - Assessment ${assessmentId}`
        getAssessmentDetails()
        return ()=>{clearTimeout(timer)}
    },[])

    useEffect(()=>{
        if(assessmentDetails.startTime && !assessmentDetails.endTime && !timer){
            timer = setInterval(()=>{
                const t = moment.duration(getTime(assessmentDetails.startTime).add(assessmentDetails.duration,"minutes").diff(moment())).asSeconds()
                if(Math.ceil(t)===600){
                    toast.info("Hurry up! Ten minutes remaining!")
                }
                if(Math.ceil(t)===60){
                    toast.warn("The assessment will be auto-submitted after 1 minute")
                }
                if(Math.ceil(t)<=0){
                    clearTimeout(timer)
                    onSubmit()
                }
                setTimeRemaining(t)
            }, 1000)
        }
    },[assessmentDetails])

    const toggleShowInstructions = ()=>{
        setShowInstructions(!showInstructions)
    }

    const onStartNow = ()=>{
        const path = window.location.href.replace(window.location.origin, "")
        localStorage.removeItem(path)
        setWait(true)
        new API()
        .setFunc("startAssessment")
        .setParams(assessmentId, auth.user, auth.phone, moment().format("DD-MM-YYYY;HH:mm"))
        .call()
        .then(()=>{
            setWait(false)
            getAssessmentDetails()
        })
        .catch(showError)
    }

    var isLaterAssessment = getTime(assessmentDetails.openingTime).isAfter(moment())

    const details = isLaterAssessment && auth.role!=="admin"?(`Assessment ${assessmentId} will be open only after ${getTime(assessmentDetails.openingTime).format("Do MMMM, hh:mm a")}. ${auth.role==="admin"?"But, as you are admin you can try this test now.":"Please check back later"}`):(assessmentDetails.endTime?`• You have scored ${assessmentDetails.score}%. ${assessmentDetails.score>50?"Congrats!":""}
• You have completed the test in ${moment.duration(getTime(assessmentDetails.endTime).diff(getTime(assessmentDetails.startTime))).asMinutes()} minutes.
• Please find the answer key below.`:`• The time limit for the assessment is ${assessmentDetails.duration} minutes.
• Each question has 4 options. Please choose the most relevant and precise option as found in the course material.
• Your score depends on time taken to complete the assessment. After ${Math.ceil(assessmentDetails.duration*assessmentDetails.safePeriod)} minutes score will reduce in proportion to the time taken.
• Assessment is open till ${getTime(assessmentDetails.openingTime).add(assessmentDetails.window,"hours").format("Do MMMM, hh:mm a")}. After that you will not be able to take the assessment.`)

    var questions = []

    if(assessmentDetails.file){
        questions = new Array(assessmentDetails.file.length)
        for(var i in assessmentDetails.file){
            questions[i]=assessmentDetails.file[i]
        }
    }

    const green = "#b2dfdb"
    const yellow = "#ffecb3"
    const red = "#ffccbc"
    var backgroundColor = green
    var duration = assessmentDetails.duration*60
    var timeElapsed = duration - timeRemaining

    if(timeElapsed < assessmentDetails.safePeriod * duration){
        backgroundColor = green
    }
    if(timeElapsed > assessmentDetails.safePeriod * duration && timeRemaining > 600){
        backgroundColor = yellow
    }
    if(timeRemaining<600){
        backgroundColor = red
    }

    const onSubmit = ()=>{
        setWait(true)
        const path = window.location.href.replace(window.location.origin, "")
        var data = JSON.parse(localStorage.getItem(path)) || {}
        var score = 0
        var startTime = getTime(assessmentDetails.startTime)
        var endTime = moment()
        for(var i in Object.keys(assessmentDetails.file)){
            if(data[i+""]===assessmentDetails.file[i].answer){
                score++
            }
        }
        var timeTakenInMins = moment.duration(endTime.diff(startTime)).asMinutes()
        const safeDuration = assessmentDetails.duration*assessmentDetails.safePeriod
        if(timeTakenInMins > safeDuration){
            score *= (100-timeTakenInMins+safeDuration)/100
        }

        if(timeTakenInMins>assessmentDetails.duration){
            timeTakenInMins = assessmentDetails.duration
        }

        score = (score/assessmentDetails.marks)*100
        score = score.toFixed(2)

        var ans = new Array(Object.keys(assessmentDetails.file).length)
        for(var i=0; i<ans.length; i++){
            ans[i]={selected:data[i+""]}
        }

        new API()
        .setFunc("endAssessment")
        .setParams(assessmentId, auth.phone, endTime.format("DD-MM-YYYY;HH:mm"), score)
        .setAssessmentId(assessmentId)
        .setBody(ans)
        .setWrite()
        .call()
        .then(()=>{
            getAssessmentDetails()
            top.current.scrollIntoView()
        })
        .catch(showError)
    }



    return <div>
        <span ref={top}/>
        <Header/>

        {assessmentDetails.startTime && Math.ceil(timeRemaining)>=0 && !assessmentDetails.endTime?<Progress
            waitText={`${zeroPad(Math.floor(timeRemaining/60),2)}:${zeroPad(Math.floor(timeRemaining%60), 2)} minutes remaining`}
            progress={100-(timeRemaining/duration)*100}
            backgroundColor={backgroundColor}/>:null}

        <ToastContainer
            position="top-right"
            autoClose={false}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <Accordion className="instructions" expanded={showInstructions} onChange={scheduledAssessment==0?null:toggleShowInstructions}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography >{
                <div className={"accordianHeader"}>
                    <div className={classes.heading}>{scheduledAssessment===1?assessmentDetails.name : (scheduledAssessment === 0 ? `Assessment ${assessmentId} is not yet scheduled. Please check back later.` : <Loader/>)}</div>
                    <div className={classes.instructions}>{assessmentDetails.startTime?(assessmentDetails.endTime?"Completed":`Start Time: ${getTime(assessmentDetails.startTime).format("hh:mm a")}`):null}</div>
                </div>
            }</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Typography className={classes.instructions}>{details}</Typography>
                {assessmentDetails.startTime || (isLaterAssessment && auth.role!="admin")?null:(wait?<Loader/>:<Button variant="contained" className={classes.startNow} color="primary" onClick={onStartNow}>Start Now</Button>)}
            </AccordionDetails>
        </Accordion>

        {
            assessmentDetails.startTime && assessmentDetails.file && Object.keys(assessmentDetails.file).map((i)=>{
                return(
                    <Question key={i} details={assessmentDetails.file[i]} n={i} showCorrect={!!assessmentDetails.endTime}/>
                )
            })
        }

        {assessmentDetails.startTime && !assessmentDetails.endTime && (
            <div className={classes.submitBox}>{
                wait?<Loader className={classes.submitBox}/>:<Button variant="contained" color="primary" className={classes.submit} onClick={onSubmit}>Submit</Button>
            }</div>
            )
        }
        <div style={{padding:"5vh"}}></div>
        
    </div>
}