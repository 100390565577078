import "./loader.css"

export default function Loader(props){
    const {className} = props
    return <div>
        <div className={`loader ${className||""}`}>
            <span className={"loaderdot loader1"}></span>
            <span className={"loaderdot loader2"}></span>
            <span className={"loaderdot loader3"}></span>
        </div>
    </div>
}