import React, { useEffect, } from "react";
import cookie from "../../scripts/cookie";
// import { Navbar, Container, NavDropdown } from "react-bootstrap";
import Header from "../../components/header/header";
import "./home.css"
import auth from "../../scripts/auth";




export default function Home(){

    useEffect(()=>{
        document.title=`Gita Online Course - Home Page`
        auth.redirect()
    },[])

    return <div>
        <Header/>
        <div class="assessmentLinks">
            <a href="/assessment/5">Open Assessment 5</a>
            <a href="/assessment/6">Open Assessment 6</a>
        </div>
    </div>
}