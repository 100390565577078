import React, { useEffect, } from "react";
import cookie from "../../scripts/cookie";
import { Navbar, Container, NavDropdown } from "react-bootstrap";
// import "./header.css"

const user = cookie.get("name")
const phone = cookie.get("phone")
const role = cookie.get("role")


export default function Header(){

    useEffect(()=>{
        if(!(user && phone && role)){
            window.open("/","_self")
        }
    })

    const onSignOut=()=>{
        cookie.clear()
        window.open("/","_self")
    }

    return <div>
        <Navbar>
            <Container>
                <Navbar.Brand >Gita Online Course</Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                <NavDropdown title={`${user} ${role==="admin"?"♛":""}`} id="basic-nav-dropdown" className="user">
                    <NavDropdown.Item className="signout" onClick={onSignOut}>Sign Out</NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
}