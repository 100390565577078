import React from "react";
import "./App.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './bootstrap.min.css';


import Login from "./pages/login/login.jsx"
import Home from "./pages/home/home.jsx"
import Assessment from "./pages/assessment/assessment.jsx"

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/assessment/:assessmentId"><Assessment/></Route>
          <Route path="/home"><Home/></Route>
          <Route path="/"><Login/></Route>
        </Switch>
    </Router>
  );
}